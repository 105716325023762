<template>
  <router-link :to="`/car/${car.id}`" class="col">
    <div class="card shadow-sm" id="catalog">
      <img :src="car.image" alt="photo">
      <div class="card-body">
        <p class="card-text">{{ car.name }}</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Card",
  props: {
    car: {
      type: Object,
    },
  }
}
</script>

<style>
.col :hover {
  opacity: 0.8;
}

.col {
  text-decoration: none;
}
</style>