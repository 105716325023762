<template>
  <div class="user">
    <div class="user-avatar">
      <img :src="user.avatar.url" alt="avatar">
    </div>
    <div class="user-info">
      <h2>{{ user.username }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCard",
  props: {
    user: {
      type: Object,
    }
  }
}
</script>

<style>
.user {
  width: 100%;
  display: flex;
  align-items: center;
}

.user img {
  width: 60px;
}

.user-info {
  margin-right: 10px;
}

.user-avatar {
  margin-right: 10px;
}

@media screen and (max-width: 770px) {
  .user {
    margin-bottom: 20px;
    justify-content: center;
  }

  .user-info {
    text-align: center;
  }
}
</style>