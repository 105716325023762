<template>
  <div class="container">
    <Loader v-if="!car.id" />
    <CarCard :car="car" v-else />
    <div class="album py-5">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        <Posts v-for="post in posts" :key="post.id" :post="post" />
      </div>
    </div>
  </div>
</template>

<script>

import CarCard from '../components/CarCard.vue';
import Loader from '../components/Loader.vue';
import Posts from '../components/Posts.vue';

export default {
  name: "Car",

  components: {
    CarCard,
    Loader,
    Posts,
  },

  computed: {
    car() {
      return this.$store.getters.car;
    },
    posts() {
      return this.$store.getters.posts;
    }
  },

  mounted() {
    let id = this.$route.params.id;
    this.$store.dispatch('getCar', id);

    this.$store.dispatch('getPosts', id)
  },

  destroyed() {
    this.$store.dispatch('clearCar');

    this.$store.dispatch('clearPosts')
  },
}
</script>

<style>
.album {
  text-decoration: none;
}
</style>