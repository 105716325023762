<template>
  <div id="app" class="wrapper">
    <div class="wrapper__content">
      <Header />
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
export default {
  name: 'App',
  components: {
    Header,
  }
}
</script>