<template>
  <div class="col" v-if="post && post.id">
    <div class="card shadow-sm">
      <img :src="post.img" alt="photo">
      <div class="card-body">
        <p class="card-text">{{ post.created_at }}</p>
        <p class="card-text">{{post.text.substring(0, 95)}}...</p>
        <div class="card-count">
          <div class="card-like">
            <img src="../assets/img/like.png" alt="">
            <p>{{ post.like_count }}</p>
          </div>
          <div class="card-comment">
            <img src="../assets/img/comment.png" alt="">
            <p>{{ post.comment_count }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Posts",
  props: {
    post: {
      type: Object,
    },
  }
}
</script>

<style>
.col :hover {
  opacity: 0.8;
}


.card-count {
  display: flex;
  height: 100%;
  align-items: center;
}

.card-count p {
  font-size: 15px;
}

.card-like {
  display: flex;
  height: 100%;
  margin-right: 20px;
}

.card-count img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.card-comment {
  display: flex;
}
</style>