import Vue from "vue";
import Vuex from "vuex";

import cars from "./modules/cars";
import posts from "./modules/posts";


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        cars,
        posts,
    }
});