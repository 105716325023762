import axios from "axios";

export default {

    state: {
        cars: [],
        car: {},
        user: {},
        page: 1,
    },

    getters: {
        cars: state => state.cars,
        car: state => state.car,
        user: state => state.user,
    },

    actions: {
        async getCars({ commit, state }) {
            if (state.cars.length > 0) {
                commit('UPDATE_PAGE');
            }
            let cars = await axios.get(`http://am111.05.testing.place/api/v1/cars/list?items=9&page=${state.page}`);
            commit('SET_CARS', cars.data)
        },

        async getCar({ commit }, id) {
            let car = await axios.get(`http://am111.05.testing.place/api/v1/car/${id}`);
            commit('SET_CAR', car.data)
        },

        clearCar({ commit }) {
            commit('CLEAR_CAR')
        },
    },

    mutations: {
        SET_CARS(state, cars) {
            state.cars.push(...cars);
        },

        SET_CAR(state, data) {
            state.car = data.car;
            state.user = data.user;
        },

        CLEAR_CAR(state) {
            state.car = {};
        },

        UPDATE_PAGE(state) {
            state.page++;
        }
    },
};