import axios from "axios";

export default {

    state: {
        posts: [],
        post: {},
    },

    getters: {
        posts: state => state.posts,
        post: state => state.post,
    },

    actions: {
        async getPosts({ commit }, id) {
            let posts = await axios.get(`http://am111.05.testing.place/api/v1/car/${id}/posts?page=1`);
            commit('SET_POSTS', posts.data)
        },

        clearPosts({ commit }) {
            commit('CLEAR_POSTS')
        },

        async getPost({ commit }, id) {
            let post = await axios.get(`http://am111.05.testing.place/api/v1/post/${id}`);
            commit('SET_POST', post.data)
        },

        clearPost({ commit }) {
            commit('CLEAR_POST')
        },
    },

    mutations: {
        SET_POSTS(state, data) {
            state.posts = data.posts;
        },
        CLEAR_POSTS(state) {
            state.posts = [];
        },

        SET_POST(state, data) {
            state.post = data.post;
        },
        CLEAR_POST(state) {
            state.post = {};
        },
    },
};