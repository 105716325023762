<template>
  <div class="main">
    <header data-bs-theme="dark">
      <div class="navbar navbar-dark bg-dark shadow-sm">
        <div class="container">
          <router-link to="/" class="navbar-brand d-flex align-items-center">
            <img src="../assets/img/logo.png" width="40" height="40" fill="#ffffff" stroke="currentColor"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" aria-hidden="true" class="me-2"
              viewBox="0 0 24 24">
            <strong>Avtomobilka</strong>
          </router-link>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
}
</script>

<style></style>