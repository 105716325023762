<template>
  <div class="card" v-if="car && car.id">

    <div class="card-top">
      <router-link to="/"><img src="../assets/img/back.png" alt="back"></router-link>
      <h3>{{ car.name }}</h3>
    </div>

    <div class="card-main">
      <div class="card-car">
        <img :src="car.images[0].thumbnail_url" alt="">
      </div>

      <div class="card-user">
        <h3>{{ car.brand_name }} {{ car.model_name }}</h3>
        <p>{{ car.engine_volume }} {{ car.engine_name }} {{ car.transmission_name }} {{ car.year }}</p>
        <UserCard :user="this.$store.getters.user" />
      </div>

    </div>
  </div>
</template>

<script>

import UserCard from './UserCard.vue'

export default {
  name: "CarCard",

  components: {
    UserCard,
  },

  props: {
    car: {
      type: Object,
    },
  }
}

</script>

<style>
.card-main {
  padding: 0 10px 0 10px;
}

.card-top {
  padding-top: 10px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-top img {
  width: 50px;
  margin-right: 20px;
}

.card-main {
  display: flex;
}

.card-car {
  width: 50%;
  margin-bottom: 20px;
}

.card-car img {
  width: 100%;
  border-radius: 15px;
}

.card-user {
  width: 50%;
  margin-left: 20px;
}


@media screen and (max-width: 770px) {
  .card-main {
    display: block;
    text-align: center;
  }

  .card-car {
    width: 100%;
  }

  .card-user {
    width: 100%;
    margin-left: 0;
  }

  .card-top {
    display: inline-block;
    text-align: center;
  }

  .card-top img {
    width: 10%;
  }
}</style>