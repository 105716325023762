<template>
  <div class="loader-wrapper">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    car: {
      type: Object,
    },
  }
}
</script>

<style>
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #0d6efd;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>